export const id = '733981854805393429' // Discord id

export const github = 'https://github.com/devxoren' // Your github url

export const theme = 'dark' // "dark" or "light"

export const toaster = {
    enabled: true, // State toaster visible
    title: 'My GiveawayBot', // Title your socail network ("Telegram channel", "My youtube channel" and other...)
    url: 'https://discord.gg/HrwY7Fsea2' // Your link to social network ("https://t.me/yourusername", "https://www.youtube.com/@yourusername" and other...)
}